import { gsap } from 'gsap';
import {
	defaultRegularDuration as drd,
	defaultFastDuration as dfd,
	defaultLightFastDuration as dld,
	defaultEase as de,
} from '../components/params.js';

const debug = false;
const tl = gsap.timeline();
const s = 0.5;
const y = 25;

const Faq = {
	aside: document.querySelector('#faq'),
	open: document.querySelector('.js-open-faq'),
	close: document.querySelectorAll('.js-close-faq'),
	navCloseFaq: document.querySelector('.nav .js-close-faq'),
};

const rect = Faq.navCloseFaq.getBoundingClientRect();
const d = rect.width;

function initFaq() {
	gsap.set(Faq.aside, {
		y: y,
		scale: s,
		autoAlpha: 0,
		visibility: 'hidden',
	});

	gsap.set(Faq.navCloseFaq, {
		width: 0,
		scale: 0,
	});
}

function openFaq() {
	if (!document.body.matches('.faq-is-open')) {
		tl.to(
			Faq.aside,
			{
				y: 0,
				scale: 1,
				autoAlpha: 1,
				visibility: 'visible',
				duration: dld,
				ease: de,
				onComplete: () => {
					document.body.classList.add('faq-is-open'); // set active class
				},
			}
			// `+=0.3` // Avoid htmx Flash
		)
			.to(
				Faq.navCloseFaq,
				{
					// width: `${d}rem`,
					width: `${d}px`,
					duration: dld,
					ease: de,
				},
				`-=${dld}`
			)
			.to(Faq.navCloseFaq, {
				scale: 1,
				duration: dld,
				ease: de,
			});
	}
}

function closeFaq() {
	if (document.body.matches('.faq-is-open')) {
		tl.to(Faq.aside, {
			y: y,
			scale: s,
			autoAlpha: 0,
			visibility: 'hidden',
			duration: dfd,
			ease: de,
			onComplete: () => {
				document.body.classList.remove('faq-is-open'); // remove active class
			},
		})
			.to(
				Faq.navCloseFaq,
				{
					scale: 0,
					duration: dld,
					ease: de,
				},
				`-=${dfd}`
			)
			.to(
				Faq.navCloseFaq,
				{
					width: 0,
					duration: dld,
					ease: de,
				},
				`-=${dfd}`
			);
	}
}

function faq() {
	if (debug) console.log('init faq');

	/* Init faq */
	initFaq();

	/* Open faq Panel + Button */
	if (Faq.open) {
		Faq.open.addEventListener('click', () => {
			if (!document.body.matches(`.faq-is-open`)) {
				if (debug) console.log('open faq');
				openFaq();
			}
		});
	}

	/* Close faq Panel + Button */
	if (Faq.close) {
		Faq.close.forEach((close) => {
			close.addEventListener('click', () => {
				if (document.body.matches(`.faq-is-open`)) {
					if (debug) console.log('close faq');
					closeFaq();
				}
			});
		});
	}
}

export { Faq, faq };
