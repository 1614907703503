import 'htmx.org';
import './components/htmx.js';
import { setVh } from './components/helpers.js';
import { alerts } from './components/alerts.js';
import { lazyload } from './components/lazyload.js';
import { showreel } from './components/showreel.js';
import { search } from './components/search.js';
import { plyr } from './components/plyr.js';
import { info, showInfoItems } from './views/info.js';
import { faq } from './views/faq.js';

const debug = false;

(function init() {
	if (document.querySelector('.html')) setVh();
	if (document.querySelector('.view-alerts')) alerts();
	if (document.querySelector('[class*="lazy"]')) lazyload();
	if (document.querySelector('#showreel')) showreel();
	if (document.querySelector('.aside-info')) info();
	if (document.querySelector('.aside-faq')) faq();
	if (debug) console.log('%cInit main.js 🌱', 'color:green');
})();

/* Htmx Events */
document.body.addEventListener('htmx:afterSwap', function (evt) {
	const htmxElement = evt.detail.elt;
	// if (debug) console.log(htmxElement.id);

	if (htmxElement.id === 'faq') {
		if (debug) console.log(`htmx:afterSwap "${htmxElement.id}"`);
		search();
		if (document.querySelector('.player')) plyr();
		if (document.querySelector('[class*="lazy"]')) lazyload();
	}

	if (htmxElement.id === 'info') {
		if (debug) console.log(`htmx:afterSwap "${htmxElement.id}"`);
		showInfoItems();
	}
});
