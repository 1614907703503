import { gsap } from 'gsap';
import {
	defaultRegularDuration as drd,
	defaultFastDuration as dfd,
	defaultLightFastDuration as dld,
	defaultEase as de,
} from './params.js';

const debug = false; // verbose debug option
const tl = gsap.timeline(); // avoid issues when clearing main timeline
const bar = gsap.timeline(); // main timeline
const y = 50; // gsap y translate

const Search = {
	bar: document.querySelector('#search'),
	icon: document.querySelector('.js-search-icon'),
	clear: document.querySelectorAll('.js-clear-search'),
	clearBtn: document.querySelector('.bar-wrapper .js-clear-search'),
	container: document.querySelector('.aside-faq'),
};

/**
 *   ==========================================================================
 *   	0 - Active Search
 *   ==========================================================================
 */

/**
 *   Open search - init some states
 */

function openSearch() {
	if (debug) console.log(`%cSearch active`, 'color:orange');

	/* search active state */
	if (!Search.container.matches(`.search-active-state`)) {
		Search.container.classList.add(`search-active-state`);
	}

	/* put clear btn in start position */
	gsap.set(Search.clearBtn, {
		scale: 0,
	});

	/* init clear search with clear && clearBtn */
	Search.clearBtn.addEventListener('click', clearSearch);
}

/**
 *   Close search
 */

function closeSearch() {
	if (debug) console.log(`%cSearch inactive`, 'color:orange');

	/* search active state */
	if (Search.container.matches(`.search-active-state`)) {
		Search.container.classList.remove(`search-active-state`);
	}
}

/**
 *   Set bar active
 *   Show clear btn and hide search icon
 */

function setBarActive() {
	if (!document.body.matches('.seach-bar-active')) {
		bar.to(Search.icon, {
			scale: 0,
			duration: dld,
			ease: de,
		}).to(Search.clearBtn, {
			scale: 1,
			duration: dld,
			ease: de,
			onComplete: () => {
				document.body.classList.add('seach-bar-active');
			},
		});
	}
}

/**
 *   Set bar inactive
 *   Show clear btn and hide search icon
 */

function setBarInactive() {
	if (document.body.matches('.seach-bar-active')) {
		bar.to(Search.clearBtn, {
			scale: 0,
			duration: dld,
			ease: de,
		}).to(Search.icon, {
			scale: 1,
			duration: dld,
			ease: de,
			onComplete: () => {
				document.body.classList.remove('seach-bar-active');
			},
		});
	}
}

/**
 *   Clear Search
 */

function clearSearch() {
	if (debug) console.log('Clearing search input value');

	/* empty search value */
	Search.bar.value = '';

	/* trigger update */
	updateSearch();
}

/**
 *   ==========================================================================
 *   	1 - Search Hints
 *   ==========================================================================
 */

/**
 *   Search hints
 */

function hints() {
	if (debug) console.log(`%cSearching hints`, 'color:orange');

	let input = document.querySelector('input#search');

	/* set listener(s) */
	/* remember, you tried 'change' event - it did not work /!\ - keep 'input' */
	// input.addEventListener('input', () => updateSearch());

	/**
	 *   Call with delay after keyup
	 *   https://stackoverflow.com/questions/4220126/run-javascript-function-when-user-finishes-typing-instead-of-on-key-up
	 *
	 */

	/* timer identifyer */
	var typingTimer;
	/* interval after typing - delay to run function */
	var intervalAfterTyping = 350; //time in ms, 5 seconds for example

	/* clear the countdown on keydown */
	input.addEventListener('keydown', function () {
		/* clear */
		clearTimeout(typingTimer);
	});

	/* start the countdown on keyup */
	input.addEventListener('keyup', function () {
		/* clear before */
		clearTimeout(typingTimer);
		/* call function after interval */
		typingTimer = setTimeout(updateSearch, intervalAfterTyping);
	});
}

/**
 *   Update search on input value
 */

function updateSearch(value = '') {
	if (debug) console.log(`Updating search`);

	let searchValue = value;
	let searchValueLength = 0;
	searchValue = Search.bar.value;
	searchValueLength = Search.bar.value.length;

	/* Clear search first - only if there is active results */
	if (Search.container.matches(`.result-active-state`)) {
		clearResults();
	}

	if (searchValueLength > 1) {
		if (debug) console.log(`Searching...`);

		/* show clear btn */
		setBarActive();

		/* get matching hints */
		getHints(searchValue);
	} else {
		if (debug) console.log(`Nothing to search`);

		/* hide clear btn */
		setBarInactive();

		/* clear all hints */
		clearHints();

		/* clear results */
		clearResults();

		/* hide null if msg visible */
		hideNull();
	}
}

/**
 *   Get hints
 */

function getHints(value) {
	if (debug) console.log(`Searching hints for \n"${value}"`);

	/* hint active state */
	if (!Search.container.matches(`.hint-active-state`)) {
		Search.container.classList.add(`hint-active-state`);
	}

	/* get hints */
	let hints = document.querySelectorAll('.hint');
	/* convert value to lowercase - when searching by hint */
	value = value.toLowerCase();
	/* replace spaces by "-" to match the id - the id is the page's name */
	value = value.replaceAll(' ', '-');
	/* replace/remove punctuations - sanitized from the page's name */
	value = value.replace(/[!'?]/g, '');
	/* create count array */
	let count = [];

	// if (debug) console.log(`Formated value : "${value}"`);

	for (let i = 0; i < hints.length; i++) {
		const hint = hints[i];

		/* convert hint.id to lowercase */
		let string = hint.id.toLowerCase();

		// if (debug) console.log(`String id to match : "${value}"`);

		if (string.includes(value)) {
			if (debug) console.log(`Matching hint found`);

			/* add active value to count - show null if count has keys */
			count.push(i);

			/* show new hint - matching current search */
			/* checking for related state to avoid FOUT of hints */
			if (!hint.matches(`.active-hint`) && !Search.container.matches(`.related-active-state`)) {
				showHint(hint);
			}

			/* related inactive state - remove state immediatly after */
			if (Search.container.matches(`.related-active-state`)) {
				Search.container.classList.remove(`related-active-state`);
			}
		} else {
			// console.log(`Not Matching`);

			/* hide old hint(s) - not matching current search */
			if (hint.matches(`.active-hint`)) {
				// console.log(`You should no be active`);
				hideHints(hint);
			}
		}
	}

	/* Check for null count and show/hide null msg */
	setTimeout(() => {
		isNull(count);
	}, 100);
}

/**
 *   Clear all active hints
 */

function clearHints() {
	if (debug) console.log(`Clearing hint(s)`);

	/* hint inactive state */
	if (Search.container.matches(`.hint-active-state`)) {
		Search.container.classList.remove(`hint-active-state`);
	}

	/* get hints */
	let hints = document.querySelectorAll('.hint');

	for (const item of Object.values(hints)) {
		/* clear running timeline */
		tl.clear();

		/* hide all items at once */
		hideHints(item);
	}
}

/**
 *   Show hints
 */

function showHint(item) {
	let btn = item.querySelector('.btn');
	gsap.to(btn, {
		// y: 0,
		autoAlpha: 1,
		duration: 0.25,
		ease: de,
		onStart: () => {
			item.classList.add(`active-hint`);
		},
	});
}

/**
 *   Hide hints
 */

function hideHints(item) {
	let btn = item.querySelector('.btn');
	gsap.to(btn, {
		// y: y,
		autoAlpha: 0,
		duration: 0.15,
		ease: de,
		onComplete: () => {
			item.classList.remove(`active-hint`);
		},
	});
}

/**
 *   Check if search = null
 *   Show/hide null msg
 */

function isNull(count) {
	if (count.length > 0) {
		if (debug) console.log(`count = ${count.length} - Hide null`);

		/* null state inactive */
		if (Search.container.matches(`.null-state`)) {
			Search.container.classList.remove(`null-state`);
		}

		/* hide null msg */
		hideNull();
	} else {
		if (debug) console.log(`count = ${count.length} - No match at all, show null`);

		/* null state active */
		if (!Search.container.matches(`.null-state`)) {
			Search.container.classList.add(`null-state`);
		}

		/* show null msg */
		// if (!Search.container.matches(`.result-active-state`)) {
		showNull();
		// } else {
		// 	if (debug) console.log(`there's a result active, don't show null`);
		// }
	}
}

/**
 *   Show Null
 */

function showNull() {
	let n = document.querySelector('.search-null');
	let msg = n.querySelector('.null');
	tl.to(
		msg,
		{
			y: 0,
			autoAlpha: 1,
			duration: 0.2,
			// delay: 0.15,
			ease: de,
			onStart: () => {
				n.classList.add(`active-null`);
			},
		},
		`+=0.15`
	);
}

/**
 *   Hide Null
 */

function hideNull() {
	let n = document.querySelector('.search-null');
	let msg = n.querySelector('.null');
	gsap.to(msg, {
		y: y,
		autoAlpha: 0,
		duration: 0.15,
		ease: de,
		onComplete: () => {
			n.classList.remove(`active-null`);
		},
	});
}

/**
 *   ==========================================================================
 *   	2- Search Results
 *   ==========================================================================
 */

/**
 *   Init result search/filter
 */

function results() {
	if (debug) console.log(`%cSearching results`, 'color:orange');

	/* get hints */
	let hints = document.querySelectorAll('.hint');
	/* get results */
	let results = document.querySelectorAll('.result');

	for (const [key, hint] of Object.entries(hints)) {
		hint.addEventListener('click', () => {
			/* select hint - update search input value with hint */
			selectHint(hint);

			/* get/show matching results */
			getResult(hint, results);
		});
	}
}

/**
 *   Update search value on hint click
 */

function selectHint(hint) {
	if (debug) console.log(`Updating search with hint`);

	/* get input */
	let input = document.querySelector('input#search');
	/* get hint value */
	let text = hint.querySelector('.btn').innerText;
	/* new value */
	input.value = text;

	/* hide all hints */
	clearHints();

	/* get matching hints */
	updateSearch(text);
}

/**
 *   Get results
 */

function getResult(needle, haystack) {
	if (debug) console.log(`Searching results for \n"${needle.id}"`);

	/* result active state */
	if (!Search.container.matches(`.result-active-state`)) {
		Search.container.classList.add(`result-active-state`);
	}

	/* Set active search */
	for (const item of Object.values(haystack)) {
		// console.log(`${item.id}`)
		if (item.id === needle.id) {
			if (debug) console.log(`Match found ${needle.id} : ${item.id}`);

			if (!item.matches(`.active-result`)) {
				/* show matching result */
				showResult(item);
			}
		} else {
			if (debug) console.log(`Result not matching`);
		}
	}
}

/**
 *   Show filtered results
 */

function showResult(item) {
	tl.to(
		item,
		{
			y: 0,
			autoAlpha: 1,
			duration: 0.25,
			ease: de,
			onStart: () => {
				item.classList.add(`active-result`);
			},
		},
		`+=0.15`
	);
}

/**
 *   Hide filtered results
 */

function hideResult(item) {
	tl.to(item, {
		y: y,
		autoAlpha: 0,
		duration: 0.15,
		ease: de,
		onComplete: () => {
			item.classList.remove(`active-result`);
		},
	});
}

/**
 *   Clear filtered results
 */

function clearResults() {
	if (debug) console.log(`Clearing results`);

	/* result inactive state */
	if (Search.container.matches(`.result-active-state`)) {
		Search.container.classList.remove(`result-active-state`);
	}

	/* get results */
	let results = document.querySelectorAll('.active-result');

	if (results) {
		for (const item of Object.values(results)) {
			if (debug) console.log(`Active found : ${item.id}, removing`);

			/* hide results */
			hideResult(item);
		}
	}
}

/**
 *   ==========================================================================
 *   	3- See also - related questions
 *   ==========================================================================
 */

/**
 *   Update with related question
 */

function related() {
	// if (debug) console.log(`Searching by related question`);

	/* get related */
	let relatedObj = document.querySelectorAll('.related');
	/* get results */
	let results = document.querySelectorAll('.result');

	for (const rel of Object.values(relatedObj)) {
		rel.addEventListener('click', () => {
			if (debug) console.log(`Searching by related question`);

			/* related active state */
			if (!Search.container.matches(`.related-active-state`)) {
				Search.container.classList.add(`related-active-state`);
			}

			/* select rel - update search input value with rel */
			selectHint(rel);

			/* get/show matching results */
			getResult(rel, results);
		});
	}
}

/**
 *   ==========================================================================
 *   	Extra - buttons animation
 *   ==========================================================================
 */

/**
 *   Make the button shake on :hover
 */

function shake() {
	// if (debug) console.log(`Shake dat button`);

	/* get related */
	let btns = document.querySelectorAll('.btn--faq');
	/* create timeline */
	const btnTimeline = gsap.timeline({ paused: true });

	for (const btn of btns) {
		/* create animation */
		btnTimeline
			.to(btn, {
				x: '+=10',
				duration: 0.05,
				ease: 'none',
				yoyo: true,
				repeat: -1,
			})
			.to(btn, {
				x: '-=10',
				duration: 0.05,
				ease: 'none',
				yoyo: true,
				repeat: -1,
			});

		/* enter */
		btn.addEventListener('mouseenter', function () {
			btnTimeline.play();
		});

		/* leave */
		btn.addEventListener('mouseleave', function () {
			btnTimeline.pause();
		});
	}
}

/**
 *   ==========================================================================
 *   	Init search/FAQ
 *   ==========================================================================
 */

function search() {
	if (debug) console.log('init search');

	/* open/init search */
	openSearch();

	/* close search */
	for (const item of Search.clear) {
		item.addEventListener('click', () => {
			/* empty/clear search input */
			clearSearch();

			/* close search */
			closeSearch();
		});
	}

	/* init search hints */
	hints();

	/* init filter */
	results();

	/* init related - called on related click */
	related();

	/* shake dat button */
	shake();
}

export { Search, search };
