import Plyr from 'plyr';

function plyr() {
	const players = document.querySelectorAll('.player');
	for (const player of Object.values(players)) {
		console.log(`init player: ${player.classList}`);
		new Plyr(player);
	}
}

export { plyr };
