const defaultRegularDuration = 1;
const defaultFastDuration = 0.5;
const defaultLightFastDuration = 0.25;
// const defaultEase = "expo.out";
const defaultEase = "power2.out";

export {
	defaultRegularDuration,
	defaultFastDuration,
	defaultLightFastDuration,
	defaultEase
}