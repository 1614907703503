/* console success */
function success(msg, verbose = false) {
    let statement = verbose ? 'Success: ' : '';
    return console.log(`%c🌱 ${statement}${msg}`, 'color: green;');
}

/* console warn */
function warn(msg, verbose = false) {
    let statement = verbose ? 'Warning: ' : '';
    return console.log(`%c🔔 ${statement}${msg}`, 'color: chocolate;');
}

/* console error */
function error(msg, verbose = false) {
    let statement = verbose ? 'Error: ' : '';
    return console.log(`%c⛔ ${statement}${msg}`, 'color: red;');
}

/* console info */
function info(msg, verbose = false) {
    let statement = verbose ? 'Info: ' : '';
    return console.log(`%c🔎 ${statement}${msg}`, 'color: purple;');
}

/* console log */
function log(msg, verbose = false) {
    let statement = verbose ? 'Log: ' : '';
    return console.log(`%c📄 ${statement}${msg}`, 'color: grey;');
}

/* trim dots in string */
function dotTrim(string) {
    return string.replace(/\./g, '');
}

/* viewport hack */
function setVh() {
    function getVh() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    getVh();
    window.addEventListener('resize', getVh);
}

export { success, warn, error, info, log, dotTrim, setVh };
