import { gsap } from 'gsap';
import {
	defaultRegularDuration as drd,
	defaultFastDuration as dfd,
	defaultLightFastDuration as dld,
	defaultEase as de,
} from '../components/params.js';

const debug = false;
const tl = gsap.timeline();
const y = 25;
const d = 3.5;

const Info = {
	aside: document.querySelector('.aside-info'),
	open: document.querySelector('.js-open-info'),
	close: document.querySelectorAll('.js-close-info'),
	navCloseBtn: document.querySelector('.nav .nav-info .js-close-info'),
};

function initInfo() {
	gsap.set(Info.aside, {
		xPercent: 100,
	});

	gsap.set(Info.navCloseBtn, {
		scale: 0,
		width: 0,
	});
}

function openInfo() {
	if (!document.body.matches('.info-is-open')) {
		tl.to(Info.aside, {
			xPercent: 0,
			duration: dfd,
			ease: de,
			onComplete: () => {
				document.body.classList.add('info-is-open'); // set active class
			},
		})
			.to(
				Info.open,
				{
					scale: 0,
					duration: dld,
					ease: de,
				},
				`-=${dfd}`
			)
			.set(Info.open, {
				width: 0,
				display: 'none',
			})
			.set(Info.navCloseBtn, {
				width: `initial`,
				display: 'block',
			})
			.to(Info.navCloseBtn, {
				scale: 1,
				duration: dld,
				ease: de,
			});
	}
}

function closeInfo() {
	if (document.body.matches('.info-is-open')) {
		tl.to('.block-info', {
			opacity: 0,
			y: y,
			duration: dld,
			ease: de,
			onComplete: () => {
				document.body.classList.remove('info-is-open'); // remove active class
			},
		})
			.to(Info.aside, {
				xPercent: 100,
				duration: dfd,
				ease: de,
				onComplete: () => {
					Info.aside.scrollTo(0, 0); // remove active class
				},
			})
			.to(
				Info.navCloseBtn,
				{
					scale: 0,
					duration: dld,
					ease: de,
				},
				`-=${dfd}`
			)
			.set(Info.navCloseBtn, {
				width: 0,
				display: 'none',
			})
			.set(Info.open, {
				width: `${d}rem`,
				display: 'block',
			})
			.to(Info.open, {
				scale: 1,
				duration: dld,
				ease: de,
			});
	}
}

function showInfoItems() {
	tl.to('.block-info', {
		y: 0,
		opacity: 1,
		stagger: 0.1,
		duration: dld,
		ease: de,
	});
}

const info = () => {
	if (debug) console.log('init info');

	/* Init info */
	initInfo();

	/* Open info Panel + Button */
	if (Info.open) {
		Info.open.addEventListener('click', () => {
			if (!document.body.matches(`.info-is-open`)) {
				if (debug) console.log('open info');
				openInfo();
			}
		});
	}

	/* Close info Panel + Button */
	if (Info.close) {
		Info.close.forEach((close) => {
			close.addEventListener('click', () => {
				if (document.body.matches(`.info-is-open`)) {
					if (debug) console.log('close info');
					closeInfo();
				}
			});
		});
	}
};

export { Info, info, showInfoItems };
