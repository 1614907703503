import { gsap } from 'gsap';
import { defaultRegularDuration as drd, defaultFastDuration as dfd, defaultEase as de } from './params.js';

const debug = false;
const tl = gsap.timeline();
const d = 15;

const Alerts = {
	container: document.querySelector('.view-alerts'),
	close: document.querySelectorAll('.js-close-alert'),
	items: document.querySelectorAll('.block-alert'),
};

function initAlerts() {
	gsap.set('.block-alert', {
		height: 0,
	});

	gsap.set('.bubble', {
		scale: 0.6,
		autoAlpha: 0,
		yPercent: `${d}`,
	});

	gsap.set('.icon', {
		scale: 0,
		yPercent: `${d}`,
	});
}

function openAlerts(alert) {
	let bubble = alert.querySelector('.bubble');
	let icon = alert.querySelector('.icon');

	tl.to(alert, {
		height: 'auto',
		duration: dfd / 4,
		ease: de,
		onComplete: () => {
			alert.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
		},
	})
		.to(icon, {
			scale: 1,
			autoAlpha: 1,
			yPercent: 0,
			ease: 'elastic.out(1, 0.3)',
			duration: dfd * 1.25,
		})
		.to(
			bubble,
			{
				scale: 1,
				autoAlpha: 1,
				yPercent: 0,
				ease: 'elastic.out(1, 0.5)',
				duration: dfd,
			},
			`-=${dfd * 1.2}`
		);
}

function closeAlert(e) {
	let alert = e.srcElement.closest('.block-alert');
	// if (debug) console.log(e)
	// if (debug) console.log(e.srcElement)
	// if (debug) console.log(alert)
	// let tl = gsap.timeline();
	tl.to(alert, {
		scale: 0.8,
		autoAlpha: 0,
		duration: dfd / 2,
		ease: de,
	}).to(alert, {
		height: 0,
		padding: 0,
		margin: 0,
		duration: dfd / 2,
		ease: de,
		onComplete: () => {
			alert.style.display = 'none';
		},
	});
	// .set(alert, {
	// 	display: 'none',
	// });
}

function scrollAlerts() {
	if (Alerts.container.scrollTop > 0) {
		if (!Alerts.container.matches('.has-overflow')) {
			Alerts.container.classList.add('has-overflow');
		}
	} else {
		if (Alerts.container.matches('.has-overflow')) {
			Alerts.container.classList.remove('has-overflow');
		}
	}
}

function alerts() {
	initAlerts();

	Alerts.items.forEach((alert) => {
		setTimeout(() => {
			if (debug) console.log('open alert');
			openAlerts(alert);
		}, 1000);
	});

	Alerts.close.forEach((btn) => {
		btn.addEventListener('click', (e) => {
			if (debug) console.log('click alert');
			closeAlert(e);
		});
	});

	Alerts.container.addEventListener('scroll', scrollAlerts);

	if (debug) console.log('init alert');
}

export { alerts };
