/* Temporary script for showreel */
function showreel() {
	let showreels = document.querySelectorAll('.block-showreel');

	// console.log(typeof showreels);
	// console.log(showreels);

	function delayVideoStart(key, reel) {
		/* multiply key by one -> tring to number conversion */
		key = key * 1;
		/* set time */
		if (key === 0) reel.currentTime = 0;
		if (key === 1) reel.currentTime = 16;
		if (key === 2) reel.currentTime = 26;
		// console.log(`video ${key} started at ${reel.currentTime}`);
		/* start video */
		reel.play();
	}

	for (const [key, reel] of Object.entries(showreels)) {
		// console.log(`video ${key} ${reel.currentTime}`);
		reel.addEventListener(
			'loadedmetadata',
			() => {
				delayVideoStart(key, reel);
			},
			false
		);
	}
}

export { showreel };
