import LazyLoad from 'vanilla-lazyload';

/* https://github.com/verlok/vanilla-lazyload/blob/master/demos/video_autoplay.html */

function lazyload() {
	/* lazy videos */
	lazyVideos = new LazyLoad({
		elements_selector: '.video-lazy',
	});

	/* lazy faq items */
	lazyItems = new LazyLoad({
		elements_selector: '.lazy-item',
	});
}

export { lazyload };
